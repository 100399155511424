import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import * as Router from "react-router-dom";
import * as Api from "./api";
import * as Auth from "oauth2";
import * as Hooks from "hooks";
const notificationPaths = [
    "/actualites/",
    "/documents/",
    "/media/",
    "/agenda/",
    "/page/",
];
const initialState = () => {
    const unread = localStorage.getItem("notification_unread");
    return { unread: unread ? JSON.parse(unread) : [] };
};
const getDate = () => {
    const date = localStorage.getItem("notification_date");
    if (date === null) {
        const now = new Date();
        persistDate(now);
        return now;
    }
    return new Date(date);
};
const persistDate = (date) => {
    localStorage.setItem("notification_date", date.toISOString());
};
const getRead = () => {
    const read = localStorage.getItem("notification_read");
    return read ? JSON.parse(read) : [];
};
const persistRead = (read) => {
    localStorage.setItem("notification_read", JSON.stringify(read));
};
const context = React.createContext(initialState());
export function useNotificationState() {
    return React.useContext(context);
}
export default function NotificationStateProvider({ children }) {
    const state = initialState();
    const { signed } = Auth.useAuth();
    const location = Router.useLocation();
    const [unread, setUnread] = Hooks.useLocalStorage("notification_unread", state.unread);
    const fetchUnreadNotifications = React.useCallback(async () => {
        const date = getDate();
        if (location.pathname === "/notifications") {
            persistDate(new Date());
            persistRead([]);
            setUnread([]);
        }
        else {
            const usedPath = notificationPaths.some((path) => location.pathname.startsWith(path))
                ? location.pathname
                : undefined;
            const notifications = await Api.fetchUnreadNotifications(date, usedPath);
            if (notifications) {
                const read = getRead();
                if (notifications.forPath) {
                    read.push(...notifications.forPath.filter((id) => !read.includes(id)));
                    persistRead(read);
                }
                setUnread(notifications.unread.filter((id) => !read.includes(id)));
            }
        }
    }, [location.pathname, setUnread]);
    React.useEffect(() => {
        if (signed) {
            fetchUnreadNotifications();
            const interval = setInterval(fetchUnreadNotifications, 30000);
            return () => {
                clearInterval(interval);
            };
        }
        return undefined;
    }, [fetchUnreadNotifications, signed]);
    return _jsx(context.Provider, { value: { unread }, children: children });
}
